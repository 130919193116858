<template>
    <div class="card">
        <span class="m-2">Boletins de Atualizações</span>
    </div>
    <div class="card m-2">
        <Accordion class="m-2">
            <AccordionTab v-for="boletim in boletins" :key="boletim.id">
                <template #header>
                    {{formatDate(boletim.createdAt)}} -
                    <b>{{boletim.descricao}}</b>
                </template>
                <p><b>Tipo: </b>{{boletim.tipo}} </p>
                <Divider />
                <span v-html="boletim.descricaoDetalhada"></span>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService'

export default {
    data(){
        return{
            boletins: []
        }
    },
    created(){
        this.$serviceBoletins = new BaseService('/boletins/find-all-boletins');
    },
    async mounted() {
        await this.loadBoletins();
    },    
    methods:{
        async loadBoletins(){
            const { data } = await this.$serviceBoletins.findAll({});
            this.boletins = data;
        },
        formatDate(value) {
            return new Date(value).toLocaleDateString("pt-br", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
        },
    }
}
</script>

<style>
</style>
